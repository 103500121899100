$(function(){

  var pageURL = location.pathname,
  pageURLArr = pageURL.split('/'), //パスを分割して配列化する
  pageURLArrCategory = pageURLArr[1]; //パスから第1階層を取得
  console.log('pageURLArr is ' + pageURLArr);
  console.log('pageURLArrCategory is ' + pageURLArrCategory);

  $('#js_menu > ul > li > a').each(function (i, v) {
    var selfhref = $(v).attr('href'),
      hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
      hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得
      console.log('hrefArr is ' + hrefArr);
      console.log('hrefArrCategory is ' + hrefArrCategory);
    
    // パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
    if (i === 4) {
      return false;
    } else {
      if (pageURLArrCategory === hrefArrCategory) {
        $('#js-nav-top').removeClass('current');
        $(v).addClass('current');
      }
    }
  });
});
